import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/extraHub/actions'
import { getCurrentFilters, getCurrentGeographies } from 'store/extraHub/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import DataTable from 'components/DataTable/DataTable'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import EmptyState from 'components/EmptyState'
import FilteredDropdown from 'components/FilteredDropdown'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'

const createOptions = (values) => {
  return sortBy(
    values.map((value) => ({ label: value.name, value: value.id })),
    'label'
  )
}

const GeographyTable = ({ span, fetchGeography, fetchFilters }) => {
  const { translate } = useContext(LangContext)

  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [filter, setFilter] = useState('all')
  const { sectorType, sectorId } = useParams()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'name', order: 'desc' })

  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const COLS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'erps',
      headerName: translate('app.storeCompleted')
    },
    {
      field: 'storeCompletion',
      headerName: translate('app.storeCompletionRate')
    },
    {
      field: 'users',
      headerName: translate('app.userCompleted')
    },
    {
      field: 'userCompletion',
      headerName: translate('app.userCompletionRate')
    },
    {
      field: 'activeCustomers',
      headerName: translate('app.userEngaged')
    }
  ]

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.FILTERS, { sectorType, sectorId })
  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    activity: filter,
    offset,
    columnSorted: sortBy.column,
    sortDirection: sortBy.order
  })

  const isFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))

  const filters = useSelector((state) => getCurrentFilters(state))
  const data = useSelector(
    (state) =>
      getCurrentGeographies(state, { geography, offset, filter, sortBy: sortBy.column, sortDirection: sortBy.order }) ||
      []
  )

  useEffect(() => {
    setPage(1)
  }, [geography, filters, sortBy])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(data) || data.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  // Filter
  useEffect(() => {
    fetchFilters({ sectorType, sectorId, dataKey: filterDataKey })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId])

  // Table Data
  useEffect(() => {
    fetchGeography({
      sectorType,
      sectorId,
      geography,
      dataKey: geographyDataKey,
      activity: filter,
      offset,
      sortBy: sortBy.column,
      sortDirection: sortBy.order
    })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId, geography, filter, offset, sortBy])

  // Format Table Data
  useEffect(() => {
    if (isFiltersLoading || isGeographyLoading) return

    const newRows = data.map((row) => ({
      name: <GeoTableLineHeader name={row.name} linkTo={row.linkTo} />,
      erps: (
        <DataCombined
          primary={row.totalCompletedStores.toLocaleString()}
          secondary={`of ${row.totalStores.toLocaleString()}`}
        />
      ),
      storeCompletion: formatPercent(row.storeCompletion),
      users: (
        <DataCombined
          primary={row.totalCompletedUsers.toLocaleString()}
          secondary={`of ${row.totalUsers.toLocaleString()}`}
        />
      ),
      userCompletion: formatPercent(row.userCompletion),
      activeCustomers: row.engagedContacts.toLocaleString()
    }))

    setRows(newRows)
  }, [data, geography, filter, isFiltersLoading, isGeographyLoading, offset])

  const getContent = () => {
    if (isGeographyLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return (
      <DataTable
        onColumnClick={setSorted}
        activeColumn={sortBy}
        columns={COLS}
        rows={rows}
        fillContainer
        stickyHeaders
        stickyFirstColumn
      />
    )
  }

  if (isFiltersLoading) {
    return (
      <Card title="Region Performance" span={span}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  const activityOptions = [{ label: 'All Campaigns', value: 'all' }].concat(createOptions(filters || []))

  COLS[0].headerName = capitalize(geography)

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <FilteredDropdown key="extra-hub-incentive" options={activityOptions} value={filter} onChange={setFilter} />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={setGeography}
          includeActivity
          includeCampaign
          includeOwnership
          includeUser={sectorType === 'customer'}
        />
      ]}
      actions={[
        <Pagination
          key="price-capture-pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disabled={isGeographyLoading}
          disableNextButton={data.length < DEFAULT_TABLE_PAGE_SIZE}
        />
      ]}
    >
      {getContent()}
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchGeography: func,
  fetchFilters: func
}

export default connect(null, {
  fetchGeography,
  fetchFilters
})(GeographyTable)
