import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import config from 'config'
import { func, node, number, object } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { fetchCycleCampaignData, fetchCycleCampaigns } from 'store/cycleCampaigns/actions'
import { activeCycleCampaignOptions, campaignDetails } from 'store/cycleCampaigns/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { PriceCheckData, SellInData, SOMData, WeightedDistroData } from 'components/plan/CycleFocus'
import { EngagementData } from 'components/plan/CycleFocus/EngagementData'
import Spinner from 'components/Spinner'

import { CYCLE_CAMPAIGN_KPI_TYPES, DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const DataCard = ({ children }) => (
  <div className="flex h-full w-full flex-col gap-4 rounded bg-white p-4 shadow-md">{children}</div>
)

DataCard.propTypes = {
  children: node
}

const CampaignKpiListV2 = ({ campaignId, fetchCycleCampaignData }) => {
  const { translate } = useContext(LangContext)
  const { currentProductType } = useContext(SectorContext)

  const { sectorId, sectorType } = useParams()

  const currentCampaign = useSelector((state) => campaignDetails(state, { campaignId }))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_CYCLE_FOCUS, {
    sectorType,
    sectorId,
    campaignId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const kpiCardData = useMemo(() => {
    if (currentCampaign && currentCampaign.kpis && currentCampaign.kpis.length) {
      const { kpis } = currentCampaign
      const sellInData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.SELL_IN)
      const sellOutData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.SELL_OUT)
      const rateOfSalesData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.RATES_OF_SELL)
      const distroData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.WEIGHTED_DISTRO)
      const priceComplianceData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.PRICE_COMPLIANCE)
      const priceUpdateData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.PRICE_UPDATE)
      const priceSignData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.PRICE_SIGN)
      const extraHubData = kpis.find((k) => k.type === CYCLE_CAMPAIGN_KPI_TYPES.EXTRA_HUB)

      return {
        sellInData: sellInData && sellInData.isVisible ? sellInData.data : [],
        sellOutData: sellOutData && sellOutData.isVisible ? sellOutData.data : null,
        rateOfSalesData: rateOfSalesData && rateOfSalesData.isVisible ? rateOfSalesData.data : null,
        distroData: distroData && distroData.isVisible ? distroData.data : [],
        priceComplianceData: priceComplianceData && priceComplianceData.isVisible ? priceComplianceData.data : null,
        priceUpdateData: priceUpdateData && priceUpdateData.isVisible ? priceUpdateData.data : null,
        priceSignData: priceSignData && priceSignData.isVisible ? priceSignData.data : null,
        extraHubData: extraHubData && extraHubData.isVisible ? extraHubData.data : null
      }
    }

    return {
      sellInData: [],
      sellOutData: null,
      rateOfSalesData: null,
      distroData: [],
      priceComplianceData: null,
      priceUpdateData: null,
      priceSignData: null,
      extraHubData: null
    }
  }, [currentCampaign])

  useEffect(() => {
    if (sectorId && sectorType && currentProductType && campaignId)
      fetchCycleCampaignData({ campaignId, sectorId, sectorType }, dataKey)
  }, [campaignId, sectorId, sectorId])

  if (!campaignId) return <EmptyState title={translate('cycleFocus.noCampaign')} />

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner icon="spinner" />
      </SpinnerContainer>
    )

  if (!currentCampaign || !currentCampaign.kpis || !currentCampaign.kpis.length)
    return <EmptyState title={translate('cycleFocus.noKPIForCampaign')} />

  const {
    sellInData,
    sellOutData,
    rateOfSalesData,
    distroData,
    priceComplianceData,
    priceUpdateData,
    priceSignData,
    extraHubData
  } = kpiCardData

  const [storeSellin, skuSellin] = sellInData
  const [weigthedDistro, storeDistro, skuDistro] = distroData

  return (
    <div className="relative -left-6 grid w-[calc(100%+3rem)] grid-cols-12 gap-5 ">
      {/* Sell In */}
      <div className="col-span-6 xl:col-span-3">
        <DataCard>
          <SellInData
            data={{
              acceptedStores: {
                value: storeSellin?.data,
                actuals: {
                  amount: storeSellin?.actuals,
                  total: storeSellin?.total
                },
                isTracked: Boolean(storeSellin)
              },
              acceptedSKUs: {
                value: skuSellin?.data,
                actuals: {
                  amount: skuSellin?.actuals,
                  total: skuSellin?.total
                },
                isTracked: Boolean(skuSellin)
              }
            }}
          />
        </DataCard>
      </div>
      {/* ------- */}

      {/* Sell Out + Rate of sales */}
      <div className="col-span-6 xl:col-span-3">
        <DataCard>
          <SOMData
            currentProductType={currentProductType}
            timeRange="PW"
            data={{
              sellout: {
                value: sellOutData?.data || 0,
                variation: sellOutData?.variation,
                isTracked: Boolean(sellOutData)
              },
              rateOfSales: {
                value: rateOfSalesData?.data,
                variation: rateOfSalesData?.variation,
                isTracked: Boolean(rateOfSalesData)
              }
            }}
          />
        </DataCard>
      </div>
      {/* ----------------------- */}

      {/* Weighted Distro */}
      <div className="col-span-4 xl:col-span-2">
        <DataCard>
          <WeightedDistroData
            timeRange="PW"
            hidden={!config.featureFlags.distroPace}
            data={{
              distro: {
                value: weigthedDistro?.data,
                variation: weigthedDistro?.variation,
                isTracked: Boolean(weigthedDistro)
              },
              stores: {
                value: storeDistro?.data,
                variation: storeDistro?.variation,
                isTracked: Boolean(storeDistro)
              },
              skus: {
                value: skuDistro?.data,
                variation: skuDistro?.variation,
                isTracked: Boolean(skuDistro)
              }
            }}
          />
        </DataCard>
      </div>
      {/* --------------- */}

      {/* Price Check */}
      <div className="col-span-4 xl:col-span-2">
        <DataCard>
          <PriceCheckData
            timeRange="PW"
            // hidden={!config.featureFlags.pricingPace}
            data={{
              priceCompliance: {
                value: priceComplianceData?.data,
                variation: priceComplianceData?.variation,
                isTracked: Boolean(priceComplianceData)
              },
              priceUpdate: {
                value: priceUpdateData?.data,
                variation: priceUpdateData?.variation,
                isTracked: Boolean(priceUpdateData)
              },
              priceSign: {
                value: priceSignData?.data,
                variation: priceSignData?.variation,
                isTracked: Boolean(priceSignData)
              }
            }}
          />
        </DataCard>
      </div>
      {/* ----------- */}

      {/* Engagement */}
      <div className="col-span-4 xl:col-span-2">
        <DataCard>
          <EngagementData
            timeRange="PW"
            hidden={!config.featureFlags.extrahubPace}
            data={{
              value: extraHubData?.data,
              variation: extraHubData?.variation,
              isTracked: Boolean(extraHubData)
            }}
          />
        </DataCard>
      </div>
      {/* -------- */}
    </div>
  )
}

CampaignKpiListV2.propTypes = {
  campaignId: number,
  fetchCycleCampaignData: func
}

const ConnectedCampaignKpiListV2 = connect(null, { fetchCycleCampaignData })(CampaignKpiListV2)

const CycleFocusCard = ({ fetchCycleCampaigns, span }) => {
  const { translate } = useContext(LangContext)
  const { sectorId, sectorType } = useParams()

  const [campaignId, setCampaignId] = useState()
  const campaignOptions = useSelector((state) => activeCycleCampaignOptions(state, { sectorType, sectorId }))

  useEffect(() => {
    if (!sectorId || !sectorType) return
    const dataKey = createDataKey(DATAKEY_TYPES.CYCLE_CAMPAIGN, { sectorType, sectorId })
    fetchCycleCampaigns({ sectorId, sectorType, dataKey })
  }, [sectorId, sectorType])

  useEffect(() => {
    if (campaignOptions?.length) {
      setCampaignId(campaignOptions[0].value)
    }
  }, [sectorType, sectorId])

  const CycleFocusSelector = () => {
    return (
      <div className="flex w-full flex-col">
        <hr className="absolute left-0 w-full border-slate-900/10" />
        <div className="flex items-center space-x-1 pt-8 text-lg font-semibold ">
          <div className="space-x-2">
            <span className="text-slate-900">{translate('app.cycleFocus')}</span>
            <span className="text-slate-900">/</span>
          </div>
          <Dropdown onChange={(e) => setCampaignId(e.target.value)} value={campaignId} options={campaignOptions} />
        </div>
      </div>
    )
  }

  return (
    <Card
      title={translate('app.campaigns')}
      customTitle={campaignId && <CycleFocusSelector />}
      span={span}
      displayAmplify
      hideOnSmallScreens={true}
      backgroundColor="transparent"
    >
      <ConnectedCampaignKpiListV2 campaignId={campaignId} />
    </Card>
  )
}

CycleFocusCard.propTypes = {
  span: object,
  fetchCycleCampaigns: func
}

export default connect(null, { fetchCycleCampaigns })(CycleFocusCard)
