import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchGeographyInventory, fetchTableFilters } from 'store/inventory/actions'
import { getCurrentFilters, getCurrentGeography } from 'store/inventory/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import EmptyState from 'components/EmptyState'
import FilteredDropdown from 'components/FilteredDropdown'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants'
import { formatCompactNumber, formatNumber } from 'utils/formatters'
import { createDataKey, createOptions, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyFilters from '../GeographyFilters'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'

import InventoryCaptureContext from './InventoryCaptureContext'

const Geography = ({ span, fetchGeographyInventory, fetchTableFilters }) => {
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [ownership, setOwnership] = useState('all')
  const [brand, setBrand] = useState('all')
  const [material, setMaterial] = useState('all')
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'oos', order: 'desc' })

  const { translate } = useContext(LangContext)

  const COLS = [
    {
      field: 'oos',
      headerName: translate('app.OOS')
    },
    {
      field: 'missedPacks',
      headerName: translate('app.missedPacks')
    },
    {
      field: 'inventoryCapture',
      headerName: translate('app.InvCapture')
    }
  ]

  const { currentProductType: productType } = useContext(SectorContext)
  const { timeframe, vapeCategory } = useContext(InventoryCaptureContext)
  const { sectorId, sectorType } = useParams()

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  // Reset pagination when ordering changes
  useEffect(() => {
    setPage(1)
  }, [sortBy])

  const filtersDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.FILTERS, {
    sectorType,
    sectorId,
    productType,
    timeframe,
    vapeCategory,
    offset
  })
  const isFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filtersDataKey }))
  const filters = useSelector((state) => getCurrentFilters(state))

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    ownership,
    brand,
    material,
    productType,
    timeframe,
    vapeCategory,
    offset,
    sortBy: sortBy.column,
    direction: sortBy.order
  })
  const isRegionLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const geoData = useSelector((state) =>
    getCurrentGeography(state, {
      geography,
      ownership,
      brand,
      material,
      productType,
      vapeCategory,
      timeframe,
      offset,
      sortBy: sortBy.column,
      direction: sortBy.order
    })
  )

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setOwnership('all')
    setBrand('all')
    setMaterial('all')
    setPage(1)
    if (productType === 'fmc' && vapeCategory !== 'all') return

    fetchTableFilters({ dataKey: filtersDataKey, sectorType, sectorId, productType, timeframe, vapeCategory })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId, timeframe, vapeCategory, productType])

  useEffect(() => {
    if (productType === 'fmc' && vapeCategory !== 'all') return

    fetchGeographyInventory({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      geography,
      ownership,
      brand,
      material,
      productType,
      timeframe,
      vapeCategory,
      offset,
      sortBy: sortBy.column,
      direction: sortBy.order
    })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [
    sectorType,
    sectorId,
    geography,
    ownership,
    material,
    brand,
    productType,
    timeframe,
    vapeCategory,
    offset,
    sortBy
  ])

  useEffect(() => {
    if (isRegionLoading || isFiltersLoading || !geoData) return

    const newRows = geoData.map((row) => {
      return {
        name: <GeoTableLineHeader name={row.name} linkTo={row.linkTo} />,
        oos: (
          <DataCompare
            last={formatNumber(row.oos * 100)}
            variation={row.oosDiff * 100}
            reverseVariation
            variationBefore
            isPercent
          />
        ),
        missedPacks: (
          <DataCompare
            last={formatCompactNumber(row.missedPacks, { shouldStripIfInteger: true })}
            variation={row.missedPacksDiff}
            variationBefore
          />
        ),
        inventoryCapture: (
          <DataCompare
            last={formatNumber(row.inventoryCapture * 100)}
            variation={row.inventoryCaptureDiff * 100}
            isPercent
            variationBefore
          />
        ),
        trend: (
          <DataGraph
            color="#53CCF8"
            data={row.trendedOos.map((i) => ({ d: i.data * 100 }))}
            dataFormatter={formatNumber}
          />
        )
      }
    })
    setRows(newRows)

    const updatedColumns = COLS.map((col) => ({ ...col, subHeader: timeframe === '1' ? 'vs PW' : 'vs P4' }))
    setColumns([
      {
        field: 'name',
        headerName: translate(displayOptTableLabels(geography))
      },
      ...updatedColumns,
      {
        field: 'trend',
        headerName: translate('app.oosTrend'),
        isLarge: true
      }
    ])
  }, [geoData, geography, isRegionLoading, isFiltersLoading, timeframe])

  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const getContent = () => {
    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (isRegionLoading) return <WrappedSpinner icon="spinner" />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return (
      <DataTable
        onColumnClick={setSorted}
        activeColumn={sortBy}
        unClickableColumns={['trend']}
        columns={columns}
        rows={rows}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    )
  }

  if (isFiltersLoading)
    return (
      <Card span={span} title="Region performance">
        <WrappedSpinner icon="spinner" />
      </Card>
    )

  const allBrandLabel = productType === 'fmc' ? 'All Brands' : 'All Brand variants'

  const dataOptions = filters[`${productType}-${vapeCategory}-${timeframe}`]
  const ownershipOptions = [{ label: translate('app.allOwnershipTypes'), value: 'all' }].concat(
    createOptions(dataOptions?.ownershipTypes || [])
  )
  const brandsOptions = [{ label: allBrandLabel, value: 'all' }].concat(createOptions(dataOptions?.brands || []))
  const materialOptions = [{ label: translate('app.allBVLS'), value: 'all' }].concat(
    createOptions(dataOptions?.materials || [])
  )

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const hasReachedLastPage = rows.length < DEFAULT_TABLE_PAGE_SIZE
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  const appliedFilters = [ownership, brand, material].filter((value) => value !== 'all').length
  const resetFilters = () => {
    setOwnership('all')
    setBrand('all')
    setMaterial('all')
  }

  const geoFilters = [
    {
      label: translate('app.ownership'),
      searchPlaceholder: translate('app.placeholders.searchOwnership'),
      options: ownershipOptions,
      value: ownership,
      onChange: (value) => setOwnership(value)
    },
    {
      label: productType === 'fmc' ? translate('app.brands') : translate('app.brandVariant'),
      searchPlaceholder: translate('app.placeholders.searchBrand'),
      options: brandsOptions,
      value: brand,
      onChange: (value) => setBrand(value)
    },
    {
      label: translate('app.material'),
      searchPlaceholder: translate('app.placeholders.searchMaterial'),
      options: materialOptions,
      value: material,
      onChange: (value) => setMaterial(value)
    }
  ]

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilters
          key="geography-filters"
          appliedFilters={appliedFilters}
          resetFilters={resetFilters}
          filters={geoFilters.map((filter, i) => (
            <FilteredDropdown
              key={i}
              label={filter.label}
              searchPlaceholder={filter.searchPlaceholder}
              options={filter.options}
              value={filter.value}
              onChange={filter.onChange}
            />
          ))}
        />
      ]}
      actions={[
        <Pagination
          key="invcap-oos-pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disableNextButton={hasReachedLastPage}
        />
      ]}
    >
      {getContent()}
    </Card>
  )
}

Geography.propTypes = {
  span: object,
  fetchGeographyInventory: func,
  fetchTableFilters: func
}

export default connect(null, {
  fetchGeographyInventory,
  fetchTableFilters
})(Geography)
