import React from 'react'

import EmptyState from 'components/EmptyState'
import ViewHeader from 'components/ViewHeader'

const EvaluateLayout = () => {
  return (
    <div className="grid w-full grid-cols-12 gap-5">
      <ViewHeader header="Evaluate" />
      <div className="col-span-12">
        <EmptyState title="This page is not implemented yet" />
      </div>
    </div>
  )
}

export default EvaluateLayout
