import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutTableData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import Pagination from 'components/Pagination'
import Tooltip from 'components/Tooltip'
import TooltipText from 'components/TooltipText'

import {
  DATAKEY_TYPES,
  DEFAULT_TABLE_PAGE_SIZE,
  PACE_SELLOUT_MANUFACTURER_FILTERS,
  SECTOR_LEVELS
} from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import AmplifySelloutTableRowHeaderToggle, { displayOptTableLabels } from './AmplifySelloutTableRowHeaderToggle'

const TIME_COLS = {
  rolling: [
    {
      field: 'lShort',
      headerName: 'LW '
    },
    {
      field: 'vpShort',
      headerName: 'vs. PW'
    },
    {
      field: 'lMid',
      headerName: 'L4'
    },
    {
      field: 'vpMid',
      headerName: 'vs. P4'
    },
    {
      field: 'lLong',
      headerName: 'L13'
    },
    {
      field: 'vpLong',
      headerName: 'vs. P13'
    }
  ],
  pointInTime: [
    {
      field: 'lShort',
      headerName: 'CTD'
    },
    {
      field: 'vpShort',
      headerName: 'vs. PC'
    },
    {
      field: 'lMid',
      headerName: 'QTD'
    },
    {
      field: 'vpMid',
      headerName: 'vs. PQ'
    },
    {
      field: 'lLong',
      headerName: 'YTD'
    },
    {
      field: 'vpLong',
      headerName: 'vs. PY'
    }
  ]
}

// const getContribution = ({ currentProportion, currentMetric, rowL13, totalL13 }) => {
//   if (currentProportion === 'share') return formatPercent(rowL13 / totalL13)
//   if (currentMetric === 'cost') return `${formatCompactCurrency(rowL13 || 0)}/${formatCompactCurrency(totalL13)}`
//   return `${formatCompactNumber(rowL13 || 0)}/${formatCompactNumber(totalL13)}`
// }

const generateRow = (
  { sectorLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter },
  {
    id,
    geo,
    rowL13,
    totalL13,
    lShort,
    lShortVariation,
    lMid,
    lMidVariation,
    lLong,
    lLongVariation,
    l13Trendline,
    ccTarget,
    ccTargetActuals,
    linkTo
  }
) => {
  const displayName = geography === 'sku' ? `${geo} - ${id}` : geo
  const rowData = {
    rowHeader: <GeoTableLineHeader name={displayName} linkTo={linkTo} />,
    lShort: (
      <Tooltip isNumber hint={tooltipFormatter(lShort)}>
        {dataFormatter(lShort)}
      </Tooltip>
    ),
    vpShort: (
      <Tooltip isNumber hint={tooltipFormatter(lShortVariation)}>
        <DataVariation variation={lShortVariation} formatter={dataFormatter} />
      </Tooltip>
    ),
    lMid: (
      <Tooltip isNumber hint={tooltipFormatter(lMid)}>
        {dataFormatter(lMid)}
      </Tooltip>
    ),
    vpMid: (
      <Tooltip isNumber hint={tooltipFormatter(lMidVariation)}>
        <DataVariation variation={lMidVariation} formatter={dataFormatter} />
      </Tooltip>
    ),
    lLong: (
      <Tooltip isNumber hint={tooltipFormatter(lLong)}>
        {dataFormatter(lLong)}
      </Tooltip>
    ),
    vpLong: (
      <Tooltip isNumber hint={tooltipFormatter(lLongVariation)}>
        <DataVariation variation={lLongVariation} formatter={dataFormatter} />
      </Tooltip>
    ),
    l13Trend: <DataGraph color="#53CCF8" data={l13Trendline} dataFormatter={dataFormatter} />
  }

  if (sectorLevel !== 'customer') {
    rowData.contribution = formatPercent(rowL13 / totalL13, { convertDecimal: true })
  }

  if (showDisposableUnitsCtd) {
    rowData.disposableUnitsCtd = (
      <Tooltip isNumber hint={formatNumber(ccTargetActuals)}>
        {formatCompactNumber(ccTargetActuals)}
      </Tooltip>
    )
    rowData.disposableUnitsVTarget =
      !ccTarget || geography === 'sku' ? (
        '-'
      ) : (
        <Tooltip isNumber hint={formatNumber(ccTargetActuals - ccTarget)}>
          <DataVariation variation={ccTargetActuals - ccTarget} format={formatCompactNumber} />
        </Tooltip>
      )
  }
  return rowData
}

const generateCols = ({ rowHeader, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd }) => {
  const baseCols = [
    {
      field: 'rowHeader',
      headerName: displayOptTableLabels[rowHeader]
    }
  ]

  const baseColsForSectorLevel =
    selectedLevel === 'customer'
      ? baseCols
      : baseCols.concat({
          field: 'contribution',
          headerName: 'Contribution'
        })

  const colsForVapeChannelCategory = showDisposableUnitsCtd
    ? [
        {
          field: 'disposableUnitsCtd',
          headerName: 'Disposable units CTD',
          additionnal: (
            <TooltipText
              hint={
                <>
                  <p className="mb-2">CTD Disposable Units & Sell Out Target are converted based on format sold :</p>
                  <ul className="mb-2">
                    <li>- Vuse Go : Per unit, as is</li>
                    <li>- Vuse Go 800 : Per unit, as is</li>
                    <li>- Vuse Go 1K: Per unit as is</li>
                    <li>- Vuse Go XL : Unit * 2.5</li>
                    <li>- Vuse Go 5K : Unit * 5</li>
                    <li>- Vuse Go 8K : Unit * 7.5</li>
                  </ul>
                  <p>
                    Note : The actuals are not converted for BAT and competitor products. Conversions are only provided
                    for Vuse Go CTD actuals vs target section.
                  </p>
                </>
              }
            >
              <Icon icon="info-outline" />
            </TooltipText>
          )
        },
        {
          field: 'disposableUnitsVTarget',
          headerName: '(vs target)'
        }
      ]
    : []

  const trendCols = [{ field: 'l13Trend', headerName: 'Trend', isLarge: true }]

  return baseColsForSectorLevel
    .concat(colsForVapeChannelCategory)
    .concat(TIME_COLS[currentTimeDisplay])
    .concat(trendCols)
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty
const NRT_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.nrt.pos

const RenderManufacturerDropdown = ({
  currentProductType,
  currentChannel,
  onChange,
  value,
  selectedLevel,
  currentProportion
}) => {
  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === 'fmc') return FMC_OPTIONS
    if (currentProductType === 'nrt') return NRT_OPTIONS
    if (currentChannel === 'specialty') return VAPE_SPECIALTY_OPTIONS
    return VAPE_POS_OPTIONS
  }, [currentChannel, currentProductType])

  const manufacturerOptions =
    currentProductType === 'fmc' && selectedLevel === SECTOR_LEVELS.CUSTOMER
      ? MANUFACTURERS_DROPDOWN_OPTIONS.concat({
          label: 'ALL',
          value: 'ALL'
        })
      : MANUFACTURERS_DROPDOWN_OPTIONS

  const allOptions =
    currentProportion === 'share' ? manufacturerOptions : [{ label: 'All', value: 'all' }, ...manufacturerOptions]

  return <Dropdown onChange={onChange} value={value} options={allOptions} />
}

RenderManufacturerDropdown.propTypes = {
  currentProductType: string,
  currentChannel: string,
  onChange: func,
  value: string,
  selectedLevel: string,
  currentProportion: string
}

const defaultGeographyBySectorLevel = {
  [SECTOR_LEVELS.CUSTOMER]: 'brand',
  [SECTOR_LEVELS.TERRITORY]: 'headoffice',
  [SECTOR_LEVELS.DISTRICT]: 'territory',
  [SECTOR_LEVELS.REGION]: 'province',
  [SECTOR_LEVELS.NATIONAL]: 'region'
}

const defaultSortAndPageData = {
  limit: DEFAULT_TABLE_PAGE_SIZE,
  sortColumn: 'l13',
  sortDirection: 'desc'
}

const geographiesInMain = ['brand', 'variantLabel']

const manufacturerByProductType = {
  fmc: 'ITCAN',
  vape: 'Vuse',
  nrt: 'Zonnic'
}

const AmplifySelloutTableCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  fetchAmplifySelloutGeographyData,
  selloutDataLoading
}) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [manufacturer, setManufacturer] = useState(manufacturerByProductType[currentProductType])
  const [geography, setGeography] = useState(defaultGeographyBySectorLevel[selectedLevel])
  const [page, setPage] = useState(1)
  const [error, setError] = useState()

  if (error) console.log(error)

  useLayoutEffect(() => {
    if (currentProportion === 'share' && manufacturer === 'all')
      setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProportion])

  useLayoutEffect(() => {
    setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProductType, currentChannel])

  useEffect(() => {
    setPage(1)
  }, [currentSector?.id, selectedLevel, currentProductType, geography, manufacturer, currentVapeCategory])

  const geographiesAreInMain = geographiesInMain.includes(geography) || selectedLevel === geography
  const tableFilters = useMemo(() => {
    if (currentProductType === 'vape') return { manufacturer, vapeCategory: currentVapeCategory }
    if (currentProductType === 'fmc') return { manufacturer }
    if (currentProductType === 'nrt') return { manufacturer }
  }, [currentProductType, currentVapeCategory, manufacturer])

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const dataKey = useMemo(() => {
    if (!geography) return
    const dataKeyToUse = geographiesAreInMain
      ? DATAKEY_TYPES.AMPLIFY.SELL_OUT.MAIN
      : DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY
    return createDataKey(dataKeyToUse, {
      sectorType: selectedLevel,
      sectorId: currentSector[selectedLevel]?.id,
      productType: currentProductType,
      channel: currentChannel,
      filters: tableFilters,
      geography,
      dataType,
      offset
    })
  }, [currentSector, selectedLevel, currentProductType, currentChannel, dataType, geography, tableFilters, offset])

  useEffect(() => {
    let mounted = true
    setError()
    if (currentSector[selectedLevel]?.id && geography && dataKey && !geographiesAreInMain) {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          type: currentProductType,
          channel: currentChannel,
          geography,
          dataType,
          offset,
          filters: tableFilters,
          ...defaultSortAndPageData
        },
        dataKey
      ).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [dataKey])
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const showDisposableUnitsCtd = useMemo(
    () =>
      ['allConsumables', 'disposables'].includes(currentVapeCategory) &&
      currentChannel === 'pos' &&
      currentProductType === 'vape',
    [currentVapeCategory, currentChannel, currentProductType]
  )
  const cols = generateCols({ rowHeader: geography, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd })
  const selloutData = useSelector((state) =>
    amplifySelloutTableData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentVapeCategory,
      geography,
      currentManufacturer: manufacturer,
      dataType,
      offset,
      ...defaultSortAndPageData
    })
  )

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber

  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCurrency
      : formatNumber

  const rows =
    selloutData?.map((v) =>
      generateRow({ sectorLevel: selectedLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter }, v)
    ) || []

  const cardProps = {
    title: `${displayOptTableLabels[geography]} Performance`,
    span,
    displayAmplify: false
  }

  const emptyStateMessageId = selloutDataLoading || isLoading ? 'app.warn.isLoading' : 'app.warn.noData'
  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const hasReachedLastPage = isEmpty(selloutData) || selloutData.length < DEFAULT_TABLE_PAGE_SIZE
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  return (
    <Card
      {...cardProps}
      headerActions={[
        <RenderManufacturerDropdown
          key="amplify-sell-out-table-manufacturer-opts"
          onChange={(e) => setManufacturer(e.target.value)}
          value={manufacturer}
          currentProductType={currentProductType}
          selectedLevel={selectedLevel}
          currentChannel={currentChannel}
          currentProportion={currentProportion}
        />,
        <AmplifySelloutTableRowHeaderToggle
          key="amplify-sell-out-geography"
          geography={geography}
          setGeography={setGeography}
          currentChannel={currentChannel}
        />
      ]}
      actions={[
        <Pagination
          key="sell-out-pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disableNextButton={hasReachedLastPage}
        />
      ]}
    >
      {isEmpty(selloutData) || selloutDataLoading || isLoading ? (
        <EmptyState title={translate(emptyStateMessageId)} isLoading={selloutDataLoading || isLoading} />
      ) : (
        <>
          <DataTable columns={cols} rows={rows} fillContainer stickyFirstColumn stickyHeaders />
        </>
      )}
    </Card>
  )
}

AmplifySelloutTableCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutTableCard)
