import camelCase from 'lodash/camelCase'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { DEFAULT_TABLE_PAGE_SIZE, PACE_SELLOUT_MANUFACTURER_FILTERS, SECTOR_LEVELS } from 'utils/constants'

const manufacturerFromProps = (state, props) => props.currentManufacturer
const productTypeFromProps = (state, props) => props.activeProductType || props.currentProductType
const currentChannelFromProps = (state, props) => props.currentChannelFilter || props.currentChannel || props.channel
const currentMetricFromProps = (state, props) => props.currentMetric
const dataTypeFromProps = (state, props) => props.dataType
const currentProportionFromProps = (state, props) => props.currentProportion
const currentTimeDisplayFromProps = (state, props) => props.currentTimeDisplay
const currentVapeCategoryFromProps = (state, props) => props.currentVapeCategory
const geographyFromProps = (state, props) => props.geography
const sortAndPageProps = (state, props) => pick(props, ['sortColumn', 'sortDirection', 'offset', 'limit'])
const selectedLevelFromProps = (state, props) => props.selectedLevel

const l13DataCols = [
  'l13w1',
  'l13w2',
  'l13w3',
  'l13w4',
  'l13w5',
  'l13w6',
  'l13w7',
  'l13w8',
  'l13w9',
  'l13w10',
  'l13w11',
  'pw',
  'lw'
]

const VAPE_SPECIALTY_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty.map(({ value }) => value)
const VAPE_POS_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos.map(({ value }) => value)
// const FMC_MANUFACTURERS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos.map(({ value }) => value)

export const selloutDataFromStore = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  dataTypeFromProps,
  dataSourceFromSectorLevel,
  (activeProductType, activeChannelFilter, activeDataType, sector) => {
    if (!sector) return null
    const selloutData = sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}`]
    return selloutData
  }
)

function getVapeCategoriesForSelection(vapeCategory) {
  if (!vapeCategory) return null
  const consumables = ['openConsumables', 'closedConsumables', 'disposables']
  if (vapeCategory === 'allConsumables') return consumables
  return [vapeCategory]
}

export const brandManufacturerGroupedSelloutData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentVapeCategoryFromProps,
  selloutDataFromStore,
  (activeProductType, activeChannelFilter, vapeCategory, amplifySelloutSectorData) => {
    if (isEmpty(amplifySelloutSectorData)) return null
    const isVape = activeProductType === 'vape'
    const isNrt = activeProductType === 'nrt'
    const vapeBrandsForChannel = activeChannelFilter === 'specialty' ? VAPE_SPECIALTY_BRANDS : VAPE_POS_BRANDS
    const vapeCategoriesToInclude = getVapeCategoriesForSelection(vapeCategory)
    const filteredData = isVape
      ? amplifySelloutSectorData.filter(
          (d) => !vapeCategory || vapeCategoriesToInclude.includes(camelCase(d.vapeCategory))
        )
      : amplifySelloutSectorData // .filter(({ manufacturer }) => FMC_MANUFACTURERS.includes(manufacturer)) // PER CONVERSATION WITH SUNGJIN 2024-03-25 total is not restricted to specified manufacturers
    return groupBy(filteredData, ({ manufacturer, brand }) => {
      if (isVape)
        return vapeBrandsForChannel.some((b) => b.toUpperCase() === brand.toUpperCase()) ? brand.toUpperCase() : 'OTHER'
      if (isNrt)
        return AMPLIFY_NRT_BRANDS.some((b) => b.toUpperCase() === brand.toUpperCase()) ? brand.toUpperCase() : 'OTHER'
      return manufacturer.toUpperCase()
    })
  }
)

export const selloutL13TrendGraphData = createSelector(
  manufacturerFromProps,
  currentProportionFromProps,
  brandManufacturerGroupedSelloutData,
  (manufacturer, activeProportion, sectorData) => {
    if (isEmpty(sectorData)) return rechartsFormat({ result: null })
    const resultForBrandManufacturer =
      manufacturer === 'all' ? flatten(Object.values(sectorData)) : sectorData[manufacturer?.toUpperCase()]
    if (isEmpty(resultForBrandManufacturer)) return rechartsFormat({ result: null })
    const manufacturerTotals = getAmplifyTotals(resultForBrandManufacturer, l13DataCols, {})
    const allBrandTotals = getAmplifyTotals(flatten(Object.values(sectorData)), l13DataCols, {})
    const totals =
      activeProportion === 'share'
        ? l13DataCols.reduce(
            (acc, l13Col) => ({
              ...acc,
              [l13Col]: (manufacturerTotals[l13Col] / (allBrandTotals[l13Col] || 1)) * 100
            }),
            {}
          )
        : manufacturerTotals
    return rechartsFormat({ result: totals })
  }
)

function rechartsFormat({ result }) {
  return [
    {
      data: +result?.l13w1 || null,
      name: moment()
        .day(-7 * 13)
        .format('M/D')
    },
    {
      data: +result?.l13w2 || null,
      name: moment()
        .day(-7 * 12)
        .format('M/D')
    },
    {
      data: +result?.l13w3 || null,
      name: moment()
        .day(-7 * 11)
        .format('M/D')
    },
    {
      data: +result?.l13w4 || null,
      name: moment()
        .day(-7 * 10)
        .format('M/D')
    },
    {
      data: +result?.l13w5 || null,
      name: moment()
        .day(-7 * 9)
        .format('M/D')
    },
    {
      data: +result?.l13w6 || null,
      name: moment()
        .day(-7 * 8)
        .format('M/D')
    },
    {
      data: +result?.l13w7 || null,
      name: moment()
        .day(-7 * 7)
        .format('M/D')
    },
    {
      data: +result?.l13w8 || null,
      name: moment()
        .day(-7 * 6)
        .format('M/D')
    },
    {
      data: +result?.l13w9 || null,
      name: moment()
        .day(-7 * 5)
        .format('M/D')
    },
    {
      data: +result?.l13w10 || null,
      name: moment()
        .day(-7 * 4)
        .format('M/D')
    },
    {
      data: +result?.l13w11 || null,
      name: moment()
        .day(-7 * 3)
        .format('M/D')
    },
    {
      data: +result?.pw || null,
      name: moment()
        .day(-7 * 2)
        .format('M/D')
    },
    {
      data: +result?.lw || null,
      name: moment().day(-7).format('M/D')
    }
  ]
}

const COLORS = ['#F5BE41', '#4FAEE6', '#B4C941', '#ff5050', '#9999ff', '#669999']

function getAmplifyTotals(data, timeKeys, staticData = null) {
  return timeKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: sumBy(data, (d) => +d[key])
    }),
    { ...staticData }
  )
}

export const amplifySelloutSOMData = createSelector(
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  brandManufacturerGroupedSelloutData,
  (metric, proportion, timeDisplay, vapeCategory, amplifyData) => {
    if (isEmpty(amplifyData)) return null
    const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
    const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

    const manufacturerTotals = Object.entries(amplifyData).map(([manufacturer, manufacturerData], idx) =>
      getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod], {
        name: startCase(manufacturer),
        fill: COLORS[idx]
      })
    )

    const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
    const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
    return manufacturerTotals.map((d) => {
      const mainPeriodData = d[mainPeriod] || 0
      const previousPeriodData = d[previousPeriod] || 0
      const isShare = proportion === 'share'
      return isShare
        ? {
            ...d,
            mainPeriod: +(mainPeriodData / mainPeriodTotal),
            previousPeriod: previousPeriodData,
            variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
          }
        : {
            ...d,
            mainPeriod: +mainPeriodData,
            previousPeriod: previousPeriodData,
            variation: +(mainPeriodData - previousPeriodData)
          }
    })
  }
)

const AMPLIFY_FMC_BRANDS = [
  'du Maurier Core',
  'Belmont',
  'Export A',
  "Player's Blue",
  'Marlboro VFM',
  'Canadian Classics',
  'Macdonald SP',
  'Pall Mall',
  'John Player Standard',
  'Next',
  'LD',
  'Philip Morris by PMI',
  'Viceroy',
  'Matinee',
  'Vogue',
  'B&H',
  'Number7',
  'Mark Ten',
  'Quebec Classique'
]

const AMPLIFY_NRT_BRANDS = ['Zonnic', 'Sesh']

function amplifySellOutBrandPerfFmcData(amplifyData, metric, proportion, timeDisplay) {
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty' // pack_qty is no longer used for fmc absolute.
  const relevantData = amplifyData.filter(({ dataType }) => dataType === dataToTotal)
  const resultByManufacturer = groupBy(relevantData, ({ brand, dataType }) =>
    AMPLIFY_FMC_BRANDS.includes(brand) ? brand : 'Other'
  )
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const manufacturerTotals = Object.entries(resultByManufacturer).map(([manufacturer, manufacturerData]) =>
    getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod], { name: manufacturer })
  )
  const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
  return manufacturerTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

function amplifySellOutBrandPerfNrtData(amplifyData, metric, proportion, timeDisplay) {
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty' // pack_qty is no longer used for fmc absolute.
  const relevantData = amplifyData.filter(({ dataType }) => dataType === dataToTotal)
  const resultByManufacturer = groupBy(relevantData, 'variantLabel')

  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const manufacturerTotals = Object.entries(resultByManufacturer).map(([manufacturer, manufacturerData]) =>
    getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod], { name: manufacturer })
  )
  const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
  return manufacturerTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

function amplifySellOutBrandPerfVapeData(amplifyData, metric, proportion, timeDisplay, vapeCategory) {
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty'
  const vapeCategoriesToInclude = getVapeCategoriesForSelection(vapeCategory)
  const relevantData = amplifyData.filter(
    ({ vapeCategory, dataType }) =>
      vapeCategoriesToInclude.includes(camelCase(vapeCategory)) && dataType === dataToTotal
  )
  const resultByBrand = groupBy(relevantData, 'variantLabel')
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const brandTotals = Object.entries(resultByBrand).map(([brand, brandData]) =>
    getAmplifyTotals(brandData, [mainPeriod, previousPeriod], { name: brand })
  )
  const mainPeriodTotal = sumBy(brandTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(brandTotals, (t) => +t[previousPeriod]) || 1
  return brandTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

export const amplifySelloutBrandPerfData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  selloutDataFromStore,
  (
    activeProductType,
    activeChannelFilter,
    activeMetric,
    activeProportion,
    activeTimeDisplay,
    activeVapeCategory,
    amplifyData
  ) => {
    if (isEmpty(amplifyData)) return null
    if (activeProductType === 'fmc')
      return amplifySellOutBrandPerfFmcData(amplifyData, activeMetric, activeProportion, activeTimeDisplay)
    if (activeProductType === 'vape')
      return amplifySellOutBrandPerfVapeData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeVapeCategory
      )
    if (activeProductType === 'nrt')
      return amplifySellOutBrandPerfNrtData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeVapeCategory
      )
  }
)

const tableColsByTimeDisplay = {
  rolling: {
    lShort: 'lw',
    pShort: 'pw',
    lMid: 'l4',
    pMid: 'p4',
    lLong: 'l13',
    pLong: 'p13'
  },
  pointInTime: {
    lShort: 'cc',
    pShort: 'pc',
    lMid: 'cq',
    pMid: 'pq',
    lLong: 'cy',
    pLong: 'py'
  }
}

function getDataCols({ timeDisplay, isGeoBasedShr }) {
  const dataColsForTimeDisplay = tableColsByTimeDisplay[timeDisplay]
  const dataCols = isGeoBasedShr
    ? Object.entries(dataColsForTimeDisplay).reduce((acc, [k, v]) => ({ ...acc, [k]: `${v}_shr` }), {})
    : dataColsForTimeDisplay
  const l13Cols = isGeoBasedShr ? l13DataCols.map((c) => `${c}_shr`) : l13DataCols
  return { dataCols, l13Cols }
}

const formatTrendlineData = (geographyL13Totals, mainDataL13Totals, isGeoBasedShr, isScopeBasedShr) => {
  const calculatedData = isScopeBasedShr
    ? l13DataCols.reduce(
        (acc, l13Col) => ({
          ...acc,
          [l13Col]: (geographyL13Totals[l13Col] || 0) / (mainDataL13Totals[l13Col] || 1)
        }),
        {}
      )
    : isGeoBasedShr
    ? Object.entries(geographyL13Totals).reduce((acc, [k, v]) => ({ ...acc, [k.split('_')[0]]: v }))
    : geographyL13Totals

  const { l13w1, l13w2, l13w3, l13w4, l13w5, l13w6, l13w7, l13w8, l13w9, l13w10, l13w11, pw, lw } = calculatedData
  return [
    { d: +l13w1 },
    { d: +l13w2 },
    { d: +l13w3 },
    { d: +l13w4 },
    { d: +l13w5 },
    { d: +l13w6 },
    { d: +l13w7 },
    { d: +l13w8 },
    { d: +l13w9 },
    { d: +l13w10 },
    { d: +l13w11 },
    { d: +pw },
    { d: +lw }
  ]
}

export const amplifySelloutDataForGeography = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  geographyFromProps,
  dataTypeFromProps,
  manufacturerFromProps,
  currentVapeCategoryFromProps,
  dataSourceFromSectorLevel,
  selectedLevelFromProps,
  (
    activeProductType,
    activeChannelFilter,
    activeGeography,
    activeDataType,
    activeManufacturer,
    activeVapeCategory,
    sector,
    selectedLevelFromProps
  ) => {
    if (!sector) return null
    const geoIsSector = selectedLevelFromProps === activeGeography
    const geoIsMainData = geoIsSector || ['brand', 'variantLabel'].includes(activeGeography)
    const filter = activeProductType !== 'vape' ? activeManufacturer : `${activeManufacturer}-${activeVapeCategory}`
    const mainData = sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}`]

    const filteredMainData = getFilteredMainData(mainData, {
      activeVapeCategory,
      activeProductType,
      activeChannelFilter
    })

    const geographyData = geoIsMainData
      ? getFilteredMainData(filteredMainData, {
          activeManufacturer,
          activeVapeCategory,
          activeProductType,
          activeChannelFilter
        })
      : sector[
          `amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}-${activeGeography}-${filter}`
        ]

    const includeTargetData =
      activeProductType === 'vape' &&
      activeChannelFilter === 'pos' &&
      ['allConsumables', 'disposables'].includes(activeVapeCategory)

    return {
      mainData: filteredMainData,
      geographyData,
      geoIsMainData,
      geoIsSector,
      includeTargetData,
      sectorDetails: { id: sector.id, name: sector.name }
    }
  }
)

export const amplifySelloutKAData = createSelector(
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  amplifySelloutDataForGeography,
  (metric, proportion, timeDisplay, amplifyData) => {
    if (isEmpty(amplifyData)) return null
    const resultByHO = groupBy(amplifyData.geographyData, 'headOffice')
    const suffixForProportion = proportion === 'share' ? '_shr' : ''
    const mainPeriod = (timeDisplay === 'rolling' ? 'l4' : 'cc') + suffixForProportion
    const previousPeriod = (timeDisplay === 'rolling' ? 'p4' : 'pc') + suffixForProportion

    const brandTotals = Object.entries(resultByHO).map(([headOffice, headOfficeData]) =>
      getAmplifyTotals(headOfficeData, [mainPeriod, previousPeriod], { name: headOffice })
    )

    return brandTotals.map((d) => {
      const mainPeriodData = d[mainPeriod] || 0
      const previousPeriodData = d[previousPeriod] || 0
      return {
        ...d,
        mainPeriod: +mainPeriodData,
        previousPeriod: +previousPeriodData,
        variation: +(mainPeriodData - previousPeriodData)
      }
    })
  }
)

const rowHeaderColumnMapping = {
  customer: {
    key: 'customerId',
    label: (c) => `${c.customer || c.name} (${c.customerId || c.id})`,
    linkTo: (c) => `/${SECTOR_LEVELS.CUSTOMER}/${c.customerId}/pace/amplify/sell-out`
  },
  headoffice: { key: 'headOffice', label: (d) => d.headOffice },
  territory: {
    key: 'territoryName',
    label: (d) => d.territoryName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.TERRITORY}/${d.territoryId || d.id}/pace/amplify/sell-out`
  },
  district: {
    key: 'districtName',
    label: (d) => d.districtName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.DISTRICT}/${d.districtId || d.id}/pace/amplify/sell-out`
  },
  region: {
    key: 'regionName',
    label: (d) => d.regionName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.REGION}/${d.regionId || d.id}/pace/amplify/sell-out`
  },
  province: { key: 'province', label: (d) => d.province },
  brand: { key: 'brand', label: (d) => d.brand },
  variantLabel: { key: 'variantLabel', label: (d) => d.variantLabel },
  sku: { key: 'productId', label: (d) => d.productName }
}

function amplifySellOutTableData(amplifyData, proportion, timeDisplay, rowHeader, sortAndPageProps) {
  const targetDataColsToInclude = amplifyData.includeTargetData ? ['ccTarget', 'ccTargetActuals'] : []

  const rowHeaderColumn = rowHeaderColumnMapping[rowHeader]
  const resultByRowHeader = groupBy(amplifyData?.geographyData, rowHeaderColumn.key)
  const isShare = proportion === 'share'
  const isGeoBasedShr = isShare && !['brand', 'brand_variant', 'sku'].includes(rowHeader) && !amplifyData.geoIsSector
  // The denominator when calculating share for any actual geography level is the brand share within that geography only.
  // The denominator when calculating share for brand, variant, sku is the main scope data.
  // When the selected scope and the geography are the same, we use the main data since to avoid a redundant fetch.
  const isScopeBasedShr = isShare && !isGeoBasedShr
  const { dataCols, l13Cols } = getDataCols({ timeDisplay, isGeoBasedShr })

  const rowTotals = orderBy(
    Object.entries(resultByRowHeader).map(([header, headerData]) => {
      const headerInfo = amplifyData.geoIsSector ? amplifyData.sectorDetails : headerData[0]
      return getAmplifyTotals(
        headerData,
        Object.values(dataCols).concat(l13Cols).concat('l13').concat(targetDataColsToInclude),
        {
          id: header,
          geo: rowHeaderColumn.label(headerInfo),
          linkTo: rowHeaderColumn.linkTo && rowHeaderColumn.linkTo(headerInfo)
        }
      )
    }),
    [sortAndPageProps.sortColumn || 'l13'],
    [sortAndPageProps.sortDirection || 'desc']
  ).slice(sortAndPageProps.offset, sortAndPageProps.offset + (sortAndPageProps.limit || DEFAULT_TABLE_PAGE_SIZE))

  const lShortTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.lShort]) || 1
  const pShortTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.pShort]) || 1
  const lMidTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.lMid]) || 1
  const pMidTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.pMid]) || 1
  const lLongTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.lLong]) || 1
  const pLongTotal = sumBy(amplifyData?.mainData, (t) => +t[dataCols.pLong]) || 1
  const l13 = sumBy(amplifyData?.geographyData, (t) => +t.l13)
  const mainDataL13Totals = getAmplifyTotals(amplifyData?.mainData, l13DataCols, {})

  return rowTotals.map((d) => {
    const lShort = +d[dataCols.lShort] || 0
    const lMid = +d[dataCols.lMid] || 0
    const lLong = +d[dataCols.lLong] || 0
    const pShort = +d[dataCols.pShort] || 0
    const pMid = +d[dataCols.pMid] || 0
    const pLong = +d[dataCols.pLong] || 0

    const targetCols = amplifyData?.includeTargetData
      ? {
          ccTarget: d.ccTarget,
          ccTargetActuals: d.ccTargetActuals
        }
      : null
    const base = {
      id: d.id,
      geo: d.geo,
      productId: d.productId,
      linkTo: d.linkTo,
      rowL13: +d.l13,
      totalL13: +l13,
      l13Trendline: formatTrendlineData(d, mainDataL13Totals, isGeoBasedShr, isScopeBasedShr),
      ...targetCols
    }
    return isScopeBasedShr
      ? {
          ...base,
          lShort: +(lShort / lShortTotal),
          lShortVariation: +(lShort / lShortTotal) - +(pShort / pShortTotal),
          lMid: lMid / lMidTotal,
          lMidVariation: lMid / lMidTotal - pMid / pMidTotal,
          lLong: lLong / lLongTotal,
          lLongVariation: lLong / lLongTotal - pLong / pLongTotal
        }
      : {
          ...base,
          lShort: +lShort,
          lShortVariation: +(lShort - pShort),
          lMid: +lMid,
          lMidVariation: +(lMid - pMid),
          lLong: +lLong,
          lLongVariation: +lLong - +pLong
        }
  })
}

const getFilteredMainData = (
  mainData,
  { activeManufacturer, activeVapeCategory, activeProductType, activeChannelFilter }
) => {
  if (isEmpty(mainData)) return []
  const noManufacturerFilter = !activeManufacturer
  if (activeManufacturer === 'all') return mainData

  if (activeProductType !== 'vape' && noManufacturerFilter) return mainData

  if (activeProductType === 'fmc') {
    return mainData?.filter((d) => activeManufacturer === d.manufacturer)
  }

  if (activeProductType === 'vape') {
    const vapeCategoriesToInclude = getVapeCategoriesForSelection(activeVapeCategory)

    const vapeCategoryFilteredData = mainData?.filter(({ vapeCategory }) => {
      return vapeCategoriesToInclude.includes(camelCase(vapeCategory))
    })

    if (noManufacturerFilter) return vapeCategoryFilteredData
    const vapeBrandsForChannel = activeChannelFilter === 'specialty' ? VAPE_SPECIALTY_BRANDS : VAPE_POS_BRANDS

    return vapeCategoryFilteredData.filter(({ brand }) => {
      const meetsBrandFilter =
        activeManufacturer === 'other'
          ? !vapeBrandsForChannel.some((b) => b.toUpperCase() === brand.toUpperCase())
          : brand.toUpperCase() === activeManufacturer.toUpperCase()
      return meetsBrandFilter
    })
  }

  if (activeProductType === 'nrt') {
    return mainData.filter(({ brand }) => {
      const meetsBrandFilter =
        activeManufacturer === 'other'
          ? !AMPLIFY_NRT_BRANDS.some((b) => b.toUpperCase() === brand.toUpperCase())
          : brand.toUpperCase() === activeManufacturer.toUpperCase()
      return meetsBrandFilter
    })
  }
}

export const amplifySelloutTableData = createSelector(
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  geographyFromProps,
  amplifySelloutDataForGeography,
  sortAndPageProps,
  (activeProportion, activeTimeDisplay, activeGeography, amplifyData, sortAndPageProps) => {
    if (isEmpty(amplifyData)) return null
    return amplifySellOutTableData(amplifyData, activeProportion, activeTimeDisplay, activeGeography, sortAndPageProps)
  }
)
