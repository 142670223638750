import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { ActionHeaderProvider } from 'context/ActionHeaderContext'

const AmplifyLayout = () => {
  useEffect(() => {
    document.title = 'Amplify'
  }, [])

  return (
    <div className="grid w-full gap-5 max-md:p-5">
      <ActionHeaderProvider>
        <div className="col-span-12">
          <Outlet />
        </div>
      </ActionHeaderProvider>
    </div>
  )
}

export default AmplifyLayout
