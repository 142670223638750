import React, { useContext, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Command } from 'cmdk'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Hint from 'components/Hint'
import Icon from 'components/Icon'
import Label from 'components/Label'

import { getMeta } from 'utils/helpers'
import { cn } from 'utils/styling'

const popoverContentAnimationClasses =
  'data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade'

const itemSelectionClasses =
  "data-[disabled=true]:pointer-events-none data-[selected='true']:bg-brand-50 data-[selected=true]:text-brand-800 data-[disabled=true]:opacity-50 cursor-pointer"

function FilteredDropdown({
  options,
  searchPlaceholder,
  noResultsCustomMessage,
  label,
  secondary,
  disabled,
  hint,
  renderWithoutOptions,
  optionLabel = 'label',
  optionValue = 'value',
  shouldTranslate = true,
  shouldUpperCase = false,
  error,
  minW,
  onChange,
  ...rest
}) {
  const { translate } = useContext(LangContext)

  const [open, setOpen] = useState(false)
  // const [selectedValue, setSelectedValue] = useState(rest.value || options[0]?.value)

  if ((!options || !options.length) && !renderWithoutOptions) return null

  const meta = getMeta(rest) || {}

  const processOptionLabel = (optionLabel) => {
    let label = optionLabel

    if (shouldTranslate) {
      label = translate(optionLabel) ?? optionLabel
    }

    if (shouldUpperCase) {
      label = label.toUpperCase()
    }

    return label
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div className={minW ? 'min-w-min' : 'max-w-full'}>
        <div className={cn('notranslate', !minW && 'w-full')} translate="no">
          {label && (
            <Label>
              {translate(label)}
              {hint && <Hint hint={hint} />}
            </Label>
          )}

          <Popover.Trigger asChild>
            <button
              role="combobox"
              aria-expanded={open}
              disabled={disabled || !options?.length}
              className={cn(
                'flex h-9 w-full items-center justify-between rounded-md border-0 py-2 align-bottom text-sm font-medium text-slate-900 outline-0 transition-all focus-visible:bg-white focus-visible:shadow-md focus-visible:ring-2 focus-visible:ring-brand-500 disabled:opacity-60 data-[state=open]:bg-white data-[state=open]:shadow-md data-[state=open]:ring-2 data-[state=open]:ring-brand-500',
                secondary ? 'bg-slate-200' : 'bg-slate-500/5',
                error && 'ring-2 ring-red-500'
              )}
            >
              <span className="w-full truncate pl-3 pr-6 text-left">
                {options?.find(({ ...opt }) => opt[optionValue] === rest.value)?.label}
              </span>
              <div className="pr-2 text-gray-500">
                <Icon icon="down-chevron-form" compact />
              </div>
            </button>
          </Popover.Trigger>

          {meta.error && meta.touched && !meta.active && (
            <small className="mt-2 text-2xs text-red-500">{meta.error}</small>
          )}
        </div>
      </div>
      <Popover.Content
        side="top"
        sideOffset={8}
        className={cn(
          'z-50 min-w-[--radix-popover-trigger-width] bg-white shadow-md ring-1 ring-slate-900/10',
          popoverContentAnimationClasses
        )}
        asChild
      >
        <Command
          loop
          className="bg-popover text-popover-foreground flex h-full w-full max-w-[--radix-popover-trigger-width] flex-col overflow-hidden rounded-md"
        >
          <div className="flex items-center border-b border-b-slate-900/10">
            <Command.Input
              placeholder={(searchPlaceholder || translate('common.search')) + '...'}
              className="flex h-11 w-full border-0 bg-transparent py-3 text-sm placeholder:text-slate-500 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
            />
            <div className="pr-2 text-slate-500">
              <Icon icon="search" compact />
            </div>
          </div>
          <Command.List className="max-h-[300px] overflow-y-auto overflow-x-hidden">
            <Command.Empty className="py-6 text-center text-sm">
              {(noResultsCustomMessage || translate('common.noResultsFound')) + '...'}
            </Command.Empty>
            <Command.Group className="text-foreground overflow-hidden p-1">
              {options?.map(({ disabled = false, ...opt }, i) => {
                const label = opt[optionLabel]
                const value = opt[optionValue]

                return (
                  <Command.Item
                    disabled={disabled}
                    key={i}
                    keywords={[label.toString(), value.toString()]}
                    value={value}
                    onSelect={() => {
                      // setSelectedValue(value)
                      setOpen(false)
                      onChange?.(value)
                    }}
                    className={cn(
                      'relative flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
                      itemSelectionClasses
                    )}
                  >
                    <span className={cn('w-full truncate', rest.value === value ? 'text-brand-700' : '')}>
                      {processOptionLabel(label)}
                    </span>
                    {rest.value === value && (
                      <div className="ml-3 h-5 w-5 shrink-0 text-brand-700">
                        <Icon icon="checkmark-small" compact />
                      </div>
                    )}
                  </Command.Item>
                )
              })}
            </Command.Group>
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover.Root>
  )
}

FilteredDropdown.propTypes = {
  options: array.isRequired,
  onChange: func.isRequired,
  label: string,
  searchPlaceholder: string,
  noResultsCustomMessage: string,
  value: oneOfType([string, number]),
  secondary: bool,
  tertiary: bool,
  input: object,
  meta: object,
  disabled: bool,
  hint: string,
  renderWithoutOptions: bool,
  optionLabel: string,
  optionValue: string,
  shouldTranslate: bool,
  shouldUpperCase: bool,
  error: bool,
  minW: bool
}

export default FilteredDropdown
