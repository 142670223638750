// Note: Style template code.

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { transparentize } from 'polished'
import { bool, func, node, number, object, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningPriceCheck } from 'store/PriceCheck/actions'
import { getCurrentPriceCheck } from 'store/PriceCheck/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Header = tw.span`text-lg font-semibold text-slate-500`

const ChartWrapper = tw.div`relative flex h-14 w-14 flex-col`

const MiniPieChart = ({ value, description, amplify }) => {
  function getChartData(completionPercentage) {
    if (!completionPercentage && completionPercentage !== 0) return [0, 100]
    const completed = completionPercentage
    const toDo = 1 - completed

    return [
      {
        name: 'completionPercentage',
        value: completed,
        fill: '#53CCF8'
      },
      {
        name: 'gapPercentage',
        value: toDo,
        fill: transparentize(0.8, '#53CCF8')
      }
    ]
  }

  const chartData = getChartData(value)

  return (
    <div className="flex w-16 flex-col items-center space-y-3" onClick={amplify}>
      <ChartWrapper>
        <PieChart width={56} height={56} margin={0}>
          <Pie
            data={chartData}
            startAngle={90}
            endAngle={-270}
            dataKey="value"
            innerRadius="80%"
            outerRadius="100%"
            isAnimationActive={false}
          />
        </PieChart>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-medium text-slate-900">
          {value || value === 0 ? Math.floor(value * 100) : '-'}%
        </div>
      </ChartWrapper>
      <span className="text-ellipsis text-nowrap text-center font-medium leading-4 text-slate-500">{description}</span>
    </div>
  )
}

MiniPieChart.propTypes = {
  value: number,
  description: string,
  amplify: func
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-5">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const PriceCheckCard = ({ fetchPlanningPriceCheck, onlyTop, span, amplifyAction, complianceAmplify }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const { currentProductType } = useContext(SectorContext)

  const { sectorId, sectorType } = useMemo(
    () => ({
      sectorId: currentSector[selectedLevel]?.id,
      sectorType: selectedLevel
    }),
    [currentSector, selectedLevel]
  )

  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_PRICE_CHECK, {
    sectorType,
    sectorId,
    productType: currentProductType
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const priceCheckData = useSelector((state) => getCurrentPriceCheck(state, { activeProductType: currentProductType }))

  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (sectorType && sectorId) {
      fetchPlanningPriceCheck({ sectorId, sectorType, productType: currentProductType, dataKey })
        .then(() => isMounted.current && setError())
        .catch((e) => isMounted.current && setError(e))
    }
  }, [sectorType, sectorId, currentProductType])

  const getContent = () => {
    if (isLoading)
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )

    if (error) return <EmptyState title={`${getErrorMessage(error)}`} />
    if (isEmpty(priceCheckData)) return <EmptyState title={`No Data available`} />

    const averages =
      priceCheckData?.averages &&
      Object.entries(priceCheckData?.averages).map(([group, value]) => (
        <div key={group} className="flex items-center space-x-6 font-medium">
          <span className="w-full text-slate-500">{group}</span>
          <span className="w-full text-slate-900">
            {!value || value === 0 ? '-' : '$' + value.toLocaleString('default')}
          </span>
        </div>
      ))

    return (
      <>
        <div className="space-y-4">
          <Eyebrow />
          <div className="flex grow flex-col items-center justify-between gap-6 @lg/card:mx-6 @lg/card:flex-row @lg/card:gap-0">
            <MiniPieChart
              amplify={amplifyAction}
              value={+(priceCheckData?.graphs?.priceCapture / 100).toFixed(2)}
              description={translate('app.priceCapture')}
            />
            <MiniPieChart
              amplify={complianceAmplify}
              value={+(priceCheckData?.graphs?.totalStrategy / 100).toFixed(2)}
              description={translate('app.totalStrategy')}
            />
            <MiniPieChart
              amplify={complianceAmplify}
              value={+(priceCheckData?.graphs?.edlpCompliance / 100).toFixed(2)}
              description={translate('app.edlpCompliance')}
            />
          </div>
        </div>

        {!onlyTop && (
          <div className="space-y-5">
            <Eyebrow />
            <div>
              <DataSm header={translate('common.averagePrice')}>
                <div className="flex flex-col space-y-4">{averages}</div>
              </DataSm>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <Card title={translate('app.priceCheck')} span={span}>
      {getContent()}
    </Card>
  )
}

PriceCheckCard.propTypes = {
  amplifyAction: func,
  complianceAmplify: func,
  fetchPlanningPriceCheck: func,
  onlyTop: bool,
  span: object
}

const mapActionCreators = {
  fetchPlanningPriceCheck
}

export default connect(null, mapActionCreators)(PriceCheckCard)
