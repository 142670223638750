import React, { useContext, useEffect, useMemo } from 'react'
import AmplifySellinOrderCompletion from 'views/Amplify/Sellin/AmplifySellinOrderCompletion'
import AmplifySellinRunRate from 'views/Amplify/Sellin/AmplifySellinRunRate'
import AmplifySellinTableCard from 'views/Amplify/Sellin/AmplifySellinTableCard'
import SellinCard from 'views/plan/SellinCard'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import SellinContext, { SellinProvider } from './SellinContext'
import SellinHeader from './SellinHeader'

const AmplifySellInCards = () => {
  const { translate } = useContext(LangContext)
  const { vapeCategory } = useContext(SellinContext)
  const { currentProductType } = useContext(SectorContext)

  useEffect(() => {
    document.title = 'Amplify - Sell-In'
  }, [])

  const actualVapeCategory = useMemo(() => {
    return currentProductType === 'fmc' ? 'all' : vapeCategory
  })

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:p-0">
      <SellinHeader parentHeader={translate('app.pace.amplify')} header={translate('app.sellIn')} />
      <SellinCard
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
        displayAmplify={false}
        vapeCategory={actualVapeCategory}
      />
      <AmplifySellinOrderCompletion span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} />
      <AmplifySellinRunRate span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} vapeCategory={actualVapeCategory} />
      <AmplifySellinTableCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} vapeCategory={actualVapeCategory} />
    </div>
  )
}

const AmplifySellinLayout = () => (
  <SellinProvider>
    <AmplifySellInCards />
  </SellinProvider>
)

export default AmplifySellinLayout
