import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import tw from 'twin.macro'

import { ActionHeaderProvider } from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'

import BreadcrumbHeader from 'components/BreadcrumbHeader'
import CheckForValidScope from 'components/guards/CheckForValidScope'

import HEADER_CONTENT from './HeaderContent'

const Wrapper = tw.div`space-y-6 h-full`

const Content = tw.div`space-y-4 flex flex-col`

const ActionsLayout = () => {
  const { translate } = useContext(LangContext)

  return (
    <Wrapper>
      <div className="grid w-full grid-cols-12 gap-5 md:p-5">
        <div className="col-span-12">
          <ActionHeaderProvider>
            <BreadcrumbHeader headerContent={HEADER_CONTENT} root=":sectorType/:sectorId/actions" />
            <CheckForValidScope pageName={translate('app.actions')}>
              <Content>
                <Outlet />
              </Content>
            </CheckForValidScope>
          </ActionHeaderProvider>
        </div>
      </div>
    </Wrapper>
  )
}

export default ActionsLayout
