import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { func, number, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchSellinGeography } from 'store/Sellin/actions'
import { sellinGeographicalData } from 'store/Sellin/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Pagination from 'components/Pagination'
import SegmentControl from 'components/SegmentControl'
import Spinner from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  CURRENT_COMPARISONS_OPTIONS,
  DATAKEY_TYPES,
  DEFAULT_TABLE_PAGE_SIZE,
  DISABLE_TOGO_PERIOD,
  PERIOD_FILTERS,
  PLACEHOLDERS
} from 'utils/constants'
import { formatCompactNumber, formatGap, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const OrderCompletionBar = ({ orderCompletion, completedOrders, completedGap }) => {
  const completedPercent = Math.min(orderCompletion * 100, 100)

  return (
    <div className="flex items-center">
      <div className="h-4 w-32 rounded bg-slate-100">
        <div className="h-full rounded-md bg-brand" style={{ width: `${completedPercent}%` }} />
      </div>
      <div className="pl-3">
        {completedOrders} / {completedGap || 0}
      </div>
    </div>
  )
}

OrderCompletionBar.propTypes = {
  orderCompletion: number,
  completedOrders: number,
  completedGap: number
}

const AmplifySellinTableCard = ({ span, fetchSellinGeography, vapeCategory }) => {
  const { translate } = useContext(LangContext)
  const { sectorId, sectorType } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: translate(`period.${p}`)
  }))

  const [range, setRange] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [period, setPeriod] = useState(translatedPeriods[0].value)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'contribution', order: 'desc' })

  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const noTargets =
    geography === 'sku' || ((geography === 'brand' || geography === 'brand_variant') && currentProductType !== 'vape')

  const COLS = [
    {
      field: 'name',
      headerName: 'Geo'
    },
    {
      field: 'contribution',
      headerName: translate('sellIn.contribution')
    },
    {
      field: 'booked',
      headerName: translate('sellIn.booked')
    },
    {
      field: 'gap',
      headerName: translate('sellIn.gap'),
      hideColumn: noTargets
    },
    {
      field: 'vstarget',
      headerName: translate('sellIn.vsTarget'),
      hideColumn: noTargets
    },
    {
      field: 'vssply',
      headerName: translate('sellIn.vsSPLY')
    },
    {
      field: 'bookedtrend',
      headerName: translate('sellIn.bookedL13'),
      isLarge: true
    },
    {
      field: 'orderCompletion',
      headerName: translate('app.orderCompletion'),
      hideColumn: ['sku', 'brand', 'brand_variant'].includes(geography)
    }
  ]

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_IN.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    currentProductType,
    vapeCategory,
    period,
    range,
    offset,
    columnSorted: sortBy.column,
    sortDirection: sortBy.order
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const geographyData = useSelector((state) =>
    sellinGeographicalData(state, {
      activeProductType: currentProductType,
      geography,
      vapeCategory,
      period,
      range,
      offset,
      columnSorted: sortBy?.column,
      sortDirection: sortBy?.order
    })
  )

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  COLS[0].headerName = translate(displayOptTableLabels(geography))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setPage(1)
  }, [geography])

  useEffect(() => {
    setPage(1)
    fetchSellinGeography(
      {
        sectorType,
        sectorId,
        geography,
        period,
        currentProductType,
        vapeCategory,
        range,
        offset,
        limit: DEFAULT_TABLE_PAGE_SIZE,
        sortBy: sortBy?.column,
        sortDirection: sortBy?.order
      },
      dataKey
    )
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorId, sectorType, geography, currentProductType, period, vapeCategory, range, sortBy])

  useEffect(() => {
    fetchSellinGeography(
      {
        sectorType,
        sectorId,
        geography,
        period,
        currentProductType,
        vapeCategory,
        range,
        offset,
        limit: DEFAULT_TABLE_PAGE_SIZE,
        sortBy: sortBy?.column,
        sortDirection: sortBy?.order
      },
      dataKey
    )
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [offset])

  const tableData = useMemo(() => {
    if (isGeographyLoading) return []
    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map(
        ({
          id,
          linkTo,
          name,
          contribution,
          vsTarget,
          booked,
          gap,
          vsLastYear,
          orderCompletion,
          orderCompletionActuals,
          orderCompletionTargets,
          trended
        }) => {
          const displayName = geography === 'sku' ? `${name} - ${id}` : name
          return {
            name: <GeoTableLineHeader name={displayName} linkTo={linkTo} />,
            contribution: `${
              contribution ? formatPercent(contribution, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`,
            booked: (
              <Tooltip isNumber hint={booked}>
                {booked ? formatCompactNumber(booked) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            gap: (
              <Tooltip isNumber hint={gap}>
                {!isNaN(gap) ? formatGap(gap, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            vstarget: (
              <Tooltip isNumber hint={+vsTarget * 100}>
                {+vsTarget ? formatPercent(+vsTarget, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT}
              </Tooltip>
            ),
            vssply: <DataVariation peer variation={+vsLastYear ? +vsLastYear * 100 : 0} variationBefore isPercent />,
            bookedtrend: <DataGraph color="#53CCF8" data={trended} />,
            orderCompletion: (
              <OrderCompletionBar
                orderCompletion={orderCompletion}
                completedOrders={orderCompletionActuals}
                completedGap={orderCompletionTargets}
              />
            )
          }
        }
      )
    }
    return []
  }, [geographyData, geography, isGeographyLoading])

  const renderContent = (rows) => {
    if (isGeographyLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (!rows || !rows.length) {
      return <EmptyState title={translate('app.warn.nothingToDisplay')} />
    }

    return (
      <DataTable
        onColumnClick={setSorted}
        activeColumn={sortBy}
        unClickableColumns={['bookedtrend']}
        columns={COLS.filter(({ hideColumn }) => !hideColumn)}
        rows={rows}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    )
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key="period"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          options={translatedPeriods}
        />,
        <SegmentControl
          key="range"
          name="range"
          onChange={(e) => setRange(e.target.value)}
          value={range}
          options={CURRENT_COMPARISONS_OPTIONS}
          disabled={DISABLE_TOGO_PERIOD.includes(period)}
        />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={setGeography}
          includeBrandVariant
          includeBrand
          includeOwnership
          includeSku
        />
      ]}
      actions={[
        <Pagination
          key="pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disabled={isGeographyLoading}
          disableNextButton={tableData.length < DEFAULT_TABLE_PAGE_SIZE}
        />
      ]}
    >
      {renderContent(tableData)}
    </Card>
  )
}

AmplifySellinTableCard.propTypes = {
  span: number,
  fetchSellinGeography: func,
  vapeCategory: string
}

export default connect(null, { fetchSellinGeography })(AmplifySellinTableCard)
